import PropTypes from "prop-types";
import { motion } from "framer-motion"
// material
import { Stack,LinearProgress } from '@mui/material';

// ----------------------------------------------------------------------
ScrollTop.propTypes = {
    value: PropTypes.number,
  };

export default function ScrollTop({value}) {
    return (
        <Stack sx={{ width: '100%', color: 'grey',  position:"fixed",left:1, top:1, zIndex: 2001, }} spacing={2}  >
        <motion.div  style={{ scaleX: value }} >
         <LinearProgress  variant="buffer" value={value} valueBuffer={value} />
        </motion.div>
        </Stack>
    
    );
}