import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
// material
import { LoadingButton } from '@mui/lab';
import { Box, Grid, Card, TextField, Typography, InputAdornment, Divider } from '@mui/material';
import Iconify from '../../../components/Iconify';
// utils
import { api } from '../../../utils/apiwrapper';

// ----------------------------------------------------------------------
async function apiConnection(values, enqueueSnackbar) {
  const formData = new FormData();
  formData.append('name', values.name);
  formData.append('email', values.email);
  formData.append('phone', values.phone);
  formData.append('subject', values.subject);
  formData.append('message', values.message);

  await api.postmulti('/dapi/item/add/', formData).then((res) => {
    if (res.status === 201) {
      return enqueueSnackbar('Thank you, We will Keep in Touch Soon.', { variant: 'success' });
    }

    if (res.status === 206) {
      return enqueueSnackbar('Please fill Feilds.', { variant: 'warning' });
    }
  });
}
//-------------------------------------------------------

export default function ContactForm() {
  const { enqueueSnackbar } = useSnackbar();

  const newvalidationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().required('Email is required'),
    // phone: Yup.string().required('Phone is required'),
    // subject: Yup.string().required('Subject is required'),
    // message: Yup.string().required('Message is required').min(20),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      subject: '',
      message: '',
    },
    validationSchema: newvalidationSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        await apiConnection(values, enqueueSnackbar);
        resetForm();
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setErrors({ afterSubmit: error.code });
      }
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sx={{ color:"#edf2f4"}}>
            <Typography varient={'h5'} sx={{ pl: 2, mt: 2, mb: 1 }}>
              HI, i am
            </Typography>
            <TextField
              fullWidth
              label="Name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon={'fluent:rename-20-regular'} color="#edf2f4" width={24} height={24} />
                  </InputAdornment>
                ),
              }}
              sx={{ mb: 3 }}
            />

            <Typography varient={'h5'} sx={{ pl: 2, mt: 2, mb: 1 }}>
              Reach me at
            </Typography>
            <TextField
              fullWidth
              label="Email"
              type="email"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon={'ic:round-alternate-email'} color="#edf2f4" width={24} height={24} />
                  </InputAdornment>
                ),
              }}
              sx={{ mb: 3 }}
            />

            {/* <TextField
              fullWidth
              label="Phone"
              {...getFieldProps('phone')}
              error={Boolean(touched.phone && errors.phone)}
              helperText={touched.phone && errors.phone}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon={"carbon:phone"} color="#bcbcbc" width={24} height={24} />
                  </InputAdornment>
                ),
              }}
              sx={{ mb: 3 }}
            />
            <TextField
              fullWidth
              label="Subject"
              {...getFieldProps('subject')}
              error={Boolean(touched.subject && errors.subject)}
              helperText={touched.subject && errors.subject}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon={"icon-park-outline:topic"} color="#bcbcbc" width={24} height={24} />
                  </InputAdornment>
                ),
              }}
              sx={{ mb: 3 }}
            />
            <TextField
              fullWidth
              multiline
              minRows={3}
              label="Message"
              {...getFieldProps('message')}
              error={Boolean(touched.message && errors.message)}
              helperText={touched.message && errors.message}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon={"bx:message-square-dots"} color="#bcbcbc" width={24} height={24} />
                  </InputAdornment>
                ),
              }}
              sx={{ mb: 3 }}
            /> */}
          </Grid>
        </Grid>

        <Box sx={{ mb: 3 }} />

        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
          <LoadingButton type="submit" variant="contained" pending={isSubmitting}>
            Submit
          </LoadingButton>
        </Box>
      </Form>
    </FormikProvider>
  );
}
