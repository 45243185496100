import PropTypes from 'prop-types'
import { Typography, Box } from '@mui/material';

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { experimentalStyled as styled, useTheme } from '@mui/material/styles';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    width: '100%',
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 400],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
}));


Progress.propTypes = {
    data: PropTypes.number,
    total: PropTypes.number,
    sold: PropTypes.number,
};
export default function Progress({ data, total, sold, ...other }) {
    const theme = useTheme()
    return <Box p={theme.spacing(2)}  {...other} >
        < BorderLinearProgress variant="determinate" value={
            data
        } />

        <Typography variant="body2" noWrap sx={{ textAlign: "center" }} >
            {sold} sold out of {total}
        </Typography>
    </ Box >

}
