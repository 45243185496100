import { createSlice } from '@reduxjs/toolkit';
// utils
import {api} from '../../utils/apiwrapper';

const initialState = {
  isLoading: false,
  error: false,
  pendingOrders: [],
  pendingOrder:'',
  Orders:[],
  Order:''
};

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PENDING ORDERS
    getPendingOrdersSuccess(state, action) {
      state.isLoading = false;
      state.pendingOrders = action.payload;
    },
     // GET PENDING ORDER
    getPendingOrderSuccess(state, action) {
      state.isLoading = false;
      state.Orders = action.payload;
    },
    // GET ORDERS
    getOrdersSuccess(state, action) {
      state.isLoading = false;
      state.Orders = action.payload;
    },
    // GET ORDERS
    getOrderSuccess(state, action) {
      state.isLoading = false;
      state.Order = action.payload;
    },
   
  }
});

export default slice.reducer;

export function getPendingOrders() {
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await api.get('/api/page/user/order/pending/list/');
      dispatch(slice.actions.getPendingOrdersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPendingOrder(id) {
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await api.get(`/api/user/order/pending/get/${id}`)
      dispatch(slice.actions.getPendingOrderSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOrders() {
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await api.get('/api/category');
      dispatch(slice.actions.getCategoriesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOrder(id) {
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {     
      const response = await api.get(`/api/page/c/?q=${id}`)
      dispatch(slice.actions.getOrderSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

