// import { paramCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled, useTheme } from '@mui/material/styles';
import { Icon } from '@iconify/react';
// import twitterFill from '@iconify/icons-eva/twitter-fill';
import facebookFill from '@iconify/icons-eva/facebook-fill';
import youtubeFilled from '@iconify/icons-ant-design/youtube-filled';
import whatsAppOutlined from '@iconify/icons-ant-design/whats-app-outlined';
import instagramFilled from '@iconify/icons-ant-design/instagram-filled';
import { Container, Link, Grid, Card, Typography, Box, Tooltip, Hidden } from '@mui/material';
// routes
import { PATH_PAGE } from '../../routes/paths';
import { MIconButton } from '../../components/@material-extend';
import Logo from '../../components/Logo';


import LandingFooter from './LandingFooter';

function FooterLink({ linkTo, title }) {
  return (
    <Link to={linkTo} color="#484b4b" component={RouterLink}>
      <Typography variant="body2" Wrap>
        {' '}
        {title}
      </Typography>
    </Link>
  );
}
// backgroundColor: theme.palette.primary.main,

function AppLink({ icon, url }) {
  return (
    <a href={url}>
      <Box component="img" alt="logo" src={icon} sx={{ padding: 1 }} />
    </a>
  );
}

const RootStyle = styled(Card)(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  flexWrap: 'wrap',
  marginTop: '3em',
  marginBottom: '3em',
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5),
  // backgroundColor: theme.palette.primary.main
}));

const SOCIALS = [
  {
    name: 'Instagram',
    linkto: '#',
    icon: <Icon icon={instagramFilled} width={30} height={30} color="#D7336D" />,
  },
  {
    name: 'Facebook',
    linkto: '#',
    icon: <Icon icon={facebookFill} width={30} height={30} color="#1877F2" />,
  },
  {
    name: 'Youtube',
    linkto: '#',
    icon: <Icon icon={youtubeFilled} width={30} height={30} color="#f30f2f" />,
  },
  {
    name: 'Whatsapp',
    linkto: '#',
    icon: <Icon icon={whatsAppOutlined} width={30} height={30} color="#32a83e" />,
  },
];

export default function FooterContainer() {
  const theme = useTheme();

  const linkToAboutus = `${PATH_PAGE.about_us}`;
  const linkTo = `#`;

  const linkToFaq = `${PATH_PAGE.faqs}`;
  const linkToPrivacy = `${PATH_PAGE.privacy}`;
  const linkToTerms = `${PATH_PAGE.terms}`;
  const linkToRefund = `${PATH_PAGE.refund}`;

  return (
    <Container maxWidth="lg" sx={{ textAlign: 'center', pb:theme.spacing(5) }}>
      <Grid
        container
        sx={{
          marginTop: '3em',
          marginBottom: '3em',
          paddingTop: theme.spacing(5),
          paddingBottom: theme.spacing(5),
          borderRadius: theme.spacing(2),          
          background: 'linear-gradient(to right bottom, #430 089, #82ffa1)',
        }}
      >
        <Grid item xs={12} md={6} lg={3} px={2} sx={{ minHeight: '130px' }}>
          <Box sx={{ ml: 3, mt: 1, color: 'common.white', py: '3', textAlign: 'left' }}>
            <FooterLink title={'About us'} linkTo={linkToAboutus} />
            <FooterLink title={'Contact Us'} linkTo={linkTo} />
            <FooterLink title={'FAQs'} linkTo={linkToFaq} />
            {/* <FooterLink title={'How it works'} linkTo={linkTo} /> */}
          </Box>
        </Grid>

        <Grid item xs={12} md={6} lg={3} px={2} sx={{ minHeight: '130px' }}>
          <Box sx={{ ml: 3, mt: 2, color: 'common.white', py: '3px', textAlign: 'left' }}>
            <FooterLink title={'Privacy Policy'} linkTo={linkToPrivacy} />
            <FooterLink title={'Refund Policy'} linkTo={linkToRefund} />
            <FooterLink title={'Terms and Conditions'} linkTo={linkToTerms} />
          </Box>
        </Grid>

        <Grid item xs={12} md={6} lg={3} px={2} sx={{ minHeight: '130px' }}>
          <Box sx={{ mt: 4, textAlign: 'center', py: '3px' }}>
            {SOCIALS.map((social) => (
              <Tooltip key={social.name} title={social.name}>
                <a href={social.linkto}>
                  <MIconButton>{social.icon}</MIconButton>
                </a>
              </Tooltip>
            ))}
          </Box>
        </Grid>

        <Grid item xs={12} md={6} lg={3} px={2} sx={{ minHeight: '130px' }}>
          <Typography varient={'h5'} sx={{ pl: 2, mt: 2, mb: 1 }}>
          <Logo sx={{ width: 240, height: 72 }} />
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
