import { createSlice } from '@reduxjs/toolkit';
// utils
import { api } from '../../utils/apiwrapper';

const initialState = {
  isLoading: false,
  error: false,
  searchdata:[]
};

const slice = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
     // GET SEARCH
     getSearchSuccess(state, action) {
      state.isLoading = false;
      state.searchdata = action.payload;
    },
   
  }
});

export default slice.reducer;


export function getSearch(slug,store) {
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {      
      let storeid=''
      if (store){
        storeid = `&str=${store.slug}`;
      }
      const response = await api.get(`/api/page/s/?q=${slug}${storeid}`)
      dispatch(slice.actions.getSearchSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
