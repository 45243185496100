// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------
export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  page404: '/404',
  page500: '/500',

  promotion: '/prmotion/:refcode',
  about: '/abo0ut-us',
  contact: '/contact-us',
  home: '/',

  product: {
    root: '/product',
    detail: '/product/:productslug',
  },
  checkout: '/checkout',

  privacy: '/cms/privacy',
  terms: '/cms/terms-of-use',
  refund: '/cms/refund-policy',
  faqs: '/cms/faqs',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  account: {
    user: path(ROOTS_DASHBOARD, '/profile'),
    settings: path(ROOTS_DASHBOARD, '/settings'),
  },
  reward: {
    points: path(ROOTS_DASHBOARD, '/points'),
  },
  coupons: {
    active: path(ROOTS_DASHBOARD, '/active-coupons'),
  },
  orders: {
    orders: path(ROOTS_DASHBOARD, '/orders'),
  },
};
