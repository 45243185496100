import { Navigate, useRoutes } from 'react-router-dom';

// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import MainLayout from '../layouts/main';

// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';

// pre routes
import {inventroyRoutes} from './dashboard/inventory.routes';
// Pages

// IMPORTANT
import NotFound from '../pages/Page404';
import Page500 from '../pages/Page500';
import ComingSoon from '../pages/ComingSoon';
import Maintenance from '../pages/Maintenance';

// AUTH
import Login from '../pages/authentication/Login';
import Register from '../pages/authentication/Register';
import ResetPassword from '../pages/authentication/ResetPassword';

// CMS
import Privacy from '../pages/cms/Privacy';
import Terms from '../pages/cms/Terms';
import Refund from '../pages/cms/Refund';
import Faq from '../pages/cms/Faq';
// Base
// import Blog from '../pages/basic/Blog';
import About from '../pages/basic/About';
// Profile
import User from '../pages/user/User';
import Settings from '../pages/user/Settings';
// Home
import Home from '../pages/shop/HomePage';

// E-com
import Product from '../pages/shop/Product';
import Checkout from '../pages/shop/Checkout';

// Dashboard
import ActiveCoupons from '../pages/dashboard/coupons/ActiveCoupons';
import RedeemPoints from '../pages/dashboard/coupons/RedeemPoints';
import Orders from '../pages/orders/Orders';

import DashboardApp from '../pages/DashboardApp';
import HomePage from '../pages/HomePage';

// Help

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // {
    //   path: '/dashboard',
    //   element: (
    //     <>
    //       <DashboardLayout />
    //     </>
    //   ),
    //   children: [
    //     { path: '', element: <Navigate to="/dashboard/app" replace /> },

    //     { path: 'app', element: <DashboardApp /> },
    //     { path: 'profile', element: <User /> },
    //     { path: 'settings', element: <Settings /> },
    //     { path: 'points', element: <RedeemPoints /> },
    //     { path: 'active-coupons', element: <ActiveCoupons /> },
    //     { path: 'orders', element: <Orders /> },
    //     // new
    //     {
    //       path: 'inventory',
    //       children:inventroyRoutes
    //     }
    //   ],
    // },
    // {
    //   path: '/auth',
    //   element: (
    //     <GuestGuard>
    //       <LogoOnlyLayout />
    //     </GuestGuard>
    //   ),
    //   children: [
    //     { path: '', element: <Navigate to="/auth/login" replace /> },
    //     { path: 'login', element: <Login /> },
    //     { path: 'register', element: <Register /> },
    //     { path: 'reset-password', element: <ResetPassword /> },
    //   ],
    // },
    {
      path: '/cms',
      element: <LogoOnlyLayout />,
      children: [
        { path: '', element: <Navigate to="/" replace /> },
        { path: 'privacy', element: <Privacy /> },
        { path: 'terms-of-use', element: <Terms /> },
        { path: 'refund-policy', element: <Refund /> },
        { path: 'faqs', element: <Faq /> },
      ],
    },

    {
      path: '/maintenance',
      element: <LogoOnlyLayout />,
      children: [{ path: '', element: <Maintenance /> }],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '', element: <HomePage/> },
        { path: 'about-us', element: <About /> },
        // { path: 'prmotion/:refcode', element: <Register /> },
        // { path: 'product/:productslug', element: <Product /> },
        // { path: 'checkout', element: <Checkout /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
