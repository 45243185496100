
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import { useEffect, useState, useRef } from 'react';
// material
import { experimentalStyled as styled } from '@mui/material/styles';

import { Box, Link } from '@mui/material';
import { CarouselControlsArrowsBasic2 } from '../../../components/carousel';
// ----------------------------------------------------------------------


//----------------------------------------------------------------------

const THUMB_SIZE = 64;

const RootStyle = styled('div')(({ theme }) => {
    const isRTL = theme.direction === 'rtl';


    return {
        paddingTop: 0,
        '& .slick-slide': {
            float: isRTL ? 'right' : 'left'
        }

    }
});

const LargeImgStyle = styled('img')({
    top: 0,
    width: '100%',
    height: Window.height,
    objectFit: 'cover',
    position: 'absolute'
});

const ThumbImgStyle = styled('img')(({ theme }) => ({
    opacity: 0.48,
    width: THUMB_SIZE,
    cursor: 'pointer',
    height: THUMB_SIZE,
    margin: theme.spacing(0, 1),
    borderRadius: theme.shape.borderRadiusSm,
    '&:hover': {
        opacity: 0.72,
        transition: theme.transitions.create('opacity')
    }
}));

/*
const Thumbbox = styled('div')(({ theme }) => ({
  opacity: 0.99,
  width: THUMB_SIZE,
  cursor: 'pointer',
  textAlign: 'center',
  alignItems: 'center',
  height: THUMB_SIZE,
  margin: theme.spacing(0, 1),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.warning.main,
  color:theme.palette.common.white,
  '&:hover': {
    opacity: 0.38,
    transition: theme.transitions.create('opacity')
  }
}));
*/

// ----------------------------------------------------------------------

LargeItem.propTypes = {
    item: PropTypes.object
};

function LargeItem({ item }) {
    const { image, alt, url } = item;

    return (
        <Box
            sx={{
                position: 'relative',
                cursor: 'pointer',
                paddingTop: {
                    xs: '35%',
                    md: '35%',
                    lg: '26%'
                }

            }}
        >
            <Link href={url} variant="inherit">
                <LargeImgStyle alt={alt} src={image} />
            </Link>
        </Box>
    );
}

ThumbnailItem.propTypes = {
    item: PropTypes.object
};

function ThumbnailItem({ item }) {
    const { cover, alttext } = item;

    return (<ThumbImgStyle alt={alttext} src={cover} />);
}


/* function ThumbnailTextItem({ item }) {
    const { title } = item;
    return (
        <Thumbbox>
            <Typography variant="caption2" Wrap >{title}</Typography>
        </ Thumbbox>
    );
} */
CarouselMain.propTypes = {
    banners: PropTypes.object,
    isLoad: PropTypes.bool
};
export default function CarouselMain({ banners, isLoad }) {

    const [currentIndex, setCurrentIndex] = useState(0);
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const slider1 = useRef(null);
    const CAROUSELS = banners;

    const settings1 = {
        speed: 500,
        dots: true,
        arrows: false,
        autoplay: true,
        slidesToShow: 1,
        draggable: false,
        slidesToScroll: 1,
        adaptiveHeight: true,
        beforeChange: (current, next) => setCurrentIndex(next)
    };
    /*
      const settings2 = {
        dots: false,
        arrows: false,
        centerMode: true,
        swipeToSlide: true,
        focusOnSelect: true,
        variableWidth: true,
        centerPadding: '0px',
        slidesToShow: CAROUSELS.length > 3 ? 3 : CAROUSELS.length
      };
      */

    useEffect(() => {
        setNav1(slider1.current);
    }, []);

    const handlePrevious = () => {
    };

    const handleNext = () => {
    };

    if (!banners) {
        return null;
    }

    return (
        <RootStyle>
            <Box
                sx={{
                    zIndex: 0,
                    overflow: 'hidden',
                    position: 'relative',

                }}
            >
                <Slider {...settings1} asNavFor={nav2} ref={slider1} >
                    {CAROUSELS.map((item) => (
                        <LargeItem key={item} item={item} />
                    ))}
                </Slider>
                <CarouselControlsArrowsBasic2
                    index={currentIndex}
                    total={CAROUSELS.length}
                    onNext={handleNext}
                    onPrevious={handlePrevious}
                />
            </Box>

            {/* <Hidden smDown>
        
        <Box spacing={2}
        sx={{
          mt: -1,
          mx: 'auto',
          ...(CAROUSELS.length === 1 && { maxWidth: THUMB_SIZE * 1 + 16 }),
          ...(CAROUSELS.length === 2 && { maxWidth: THUMB_SIZE * 2 + 32 }),
          ...(CAROUSELS.length === 3 && { maxWidth: THUMB_SIZE * 3 + 48 }),
          ...(CAROUSELS.length === 4 && { maxWidth: THUMB_SIZE * 3 + 48 }),
          ...(CAROUSELS.length === 5 && { maxWidth: THUMB_SIZE * 6 }),
          '& .slick-current img': {
            opacity: 1,
            border: (theme) => `solid 3px ${theme.palette.primary.main}`
          }
        }}
      >
        
        <Slider {...settings2} asNavFor={nav1} ref={slider2}>
          {CAROUSELS.map((item) => (
             <ThumbnailItem key={item} item={item} />
          ))}
        </Slider>
        
      </Box> 
          </Hidden> */}
        </RootStyle >
    );
}
