import { createSlice } from '@reduxjs/toolkit';
// utils
import { api } from '../../utils/apiwrapper';

const initialState = {
    isLoading: false,
    error: false,
    pointdata: []

};

const slice = createSlice({
    name: 'points',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET BRAND
        getPointSuccess(state, action) {
            state.isLoading = false;
            state.pointdata = action.payload;
        },
    }
});

export default slice.reducer;


export function getPoints() {
    return async dispatch => {
        dispatch(slice.actions.startLoading());
        try {


            const response = await api.get(`/api/s/referral/summary/`)
            dispatch(slice.actions.getPointSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
