import { Navigate } from 'react-router-dom';

// Pages

import ComingSoon from '../../pages/ComingSoon';
// ----------------------------------------------------------------------

export const inventroyRoutes = [
  {
    path: 'brand',
    children: [
      { path: '', element: <Navigate to="/dashboard/brand/list" replace /> },
      { path: 'view/:brandslug', element: <ComingSoon /> },
      { path: 'list', element: <ComingSoon /> },
      { path: 'add', element: <ComingSoon /> },
      { path: 'edit/:brandslug', element: <ComingSoon /> },
    ],
  },
  {
    path: 'category',
    children: [
      { path: '', element: <Navigate to="/dashboard/category/list" replace /> },
      { path: 'view/:categoryslug', element: <ComingSoon /> },
      { path: 'list', element: <ComingSoon /> },
      { path: 'add', element: <ComingSoon /> },
      { path: 'edit/:categoryslug', element: <ComingSoon /> },
    ],
  },
  {
    path: 'itemgroup',
    children: [
      { path: '', element: <Navigate to="/dashboard/itemgroup/list" replace /> },
      { path: 'view/:itemgroupslug', element: <ComingSoon /> },
      { path: 'list', element: <ComingSoon /> },
      { path: 'add', element: <ComingSoon /> },
      { path: 'edit/:itemgroupslug', element: <ComingSoon /> },
    ],
  },
  {
    path: 'units',
    children: [
      { path: '', element: <Navigate to="/dashboard/units/list" replace /> },
      { path: 'view/:unitid', element: <ComingSoon /> },
      { path: 'list', element: <ComingSoon /> },
      { path: 'add', element: <ComingSoon /> },
      { path: 'edit/:unitid', element: <ComingSoon /> },
    ],
  },
];
