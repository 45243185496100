
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

// material/
import {
  useTheme, experimentalStyled as styled
} from '@mui/material/styles';
import { Box, Grid, Skeleton, Card, Typography, Divider } from '@mui/material';
import Label from '../../../components/Label';
import getVariant from '../../../utils/getVariant';
import { MotionInView } from '../../../components/animate';
// ----------------------------------------------------------------------

const SkeletonLoad = (
  <>
    {[...Array(12)].map((item, index) => (
      <Grid item xs={12} sm={6} md={3} key={index}>
        <Skeleton
          variant="rectangular"
          width="100%"
          sx={{ paddingTop: '115%', borderRadius: 2 }}
        />
      </Grid>
    ))}
  </>
);
// ----------------------------------------------------------------------
const BrandImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'fill',
  position: 'relative',
  borderRadius: '12px'
});



SoldoutCard.propTypes = {
  item: PropTypes.object
};

function SoldoutCard({ item }) {
  const theme = useTheme();
  const { image, prize, id, drawdate, isparticipant, series } = item;
  return (
    <Card sx={{
      p: theme.spacing(3)


    }} >
      < BrandImgStyle
        key={id}
        alt={prize}
        src={image}
        sx={{ cursor: 'pointer', boxShadow: theme.shadows[2], }
        }
      />
      <Box
        sx={{
          display: 'grid',
          alignItems: 'center',
          mt: theme.spacing(1),
        }}
      >
        < Typography variant="body" noWrap sx={{ mb: 1 }}>
          Giveaway: {prize}
        </Typography >
        < Typography variant="caption" noWrap sx={{ mb: 1 }}>
          Draw Date:{drawdate}
        </Typography >
        < Typography variant="caption" noWrap sx={{ mb: 1 }}>
          Series: {series}
        </Typography >
        <Divider
          orientation="horizontal"
          sx={{ mx: 1, height: 16, mb: 1 }}
        />
        {isparticipant ? <Label
          variant={'limited'}
          color={'error'}
          sx={{
            right: 3,
            position: 'relative',
          }}
        >
          participant
        </Label> :
          < Label
            variant={'limited'}
            color={'warning'}
            sx={{
              right: 3,
              position: 'relative',
            }}
          >
            you are not participant
          </Label>}
      </Box>
    </ Card >
  );

}

SoldoutList.propTypes = {
  sold: PropTypes.array.isRequired,
  isLoad: PropTypes.bool
};

export default function SoldoutList({ sold, isLoad, ...other }) {
  const theme = useTheme();
  const soldouts = [{
    id: 1,
    drawdate: '10-03-2022',
    prize: '60 Lakkh worth land',
    series: 'asdf1234',
    isparticipant: false,
    image: 'https://dealup-static.s3.amazonaws.com/images/items/CG-01220-Combine.png'
  },
  {
    id: 2,
    drawdate: '10-03-2022',
    prize: '60 Lakkh worth land',
    series: 'asdf1234',
    isparticipant: true,
    image: 'https://dealup-static.s3.amazonaws.com/images/items/CG-01220-Combine.png'
  }]
  return (

    < Grid container spacing={theme.spacing(1)}  {...other}>
      <Grid key={'t4rhty'} item xs={12} >
        <Typography variant="h3" sx={{ mb: 1, textAlign: 'center', fontWeight: 900 }}>
          Sold Outs
        </Typography>
      </Grid>
      {
        soldouts.map((soldout, index) => (

          <Grid key={soldout.id} item xs={12} sm={6} md={4} lg={3}>
            <MotionInView key={index} variants={getVariant('slideInUp')}>
              <SoldoutCard item={soldout} />
            </MotionInView>
          </Grid>

        ))
      }
      {isLoad && SkeletonLoad}

    </Grid >
  );
}
