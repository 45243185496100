import { createSlice } from '@reduxjs/toolkit';
// utils
import { api } from '../../utils/apiwrapper';

const initialState = {
  isLoading: false,
  error: false,
  isAdded: false,
  products: "",
  banners: "",

  categorymenulist: [],
  homedata: [],
  pincodes: [],
  stores: [],
  addressbook: []
};

const slice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },
    getBannersSuccess(state, action) {
      state.isLoading = false;
      state.banners = action.payload;
    },
    getCategoryMenuSuccess(state, action) {
      state.isLoading = false;
      state.categorymenulist = action.payload;
    },
    // GET HOME DATA
    getHomeSuccess(state, action) {
      state.isLoading = false;
      state.homedata = action.payload;
    },

    // GET PINCODES 
    getPincodesSuccess(state, action) {
      state.isLoading = false;
      state.pincodes = action.payload;
    },
    // GET Store
    getStoresSuccess(state, action) {
      state.isLoading = false;
      state.stores = action.payload;
    },
    // REMOVE PINCODE
    removePincodes(state) {
      state.isLoading = false;
      state.pincodes = [];
    },
    // GET Store
    getAddressSuccess(state, action) {
      state.isLoading = false;
      state.addressbook = action.payload;
    },


  }
});

export default slice.reducer;
// Actions
export const {
  removePincodes
} = slice.actions;

//----------------------------------------------------------------------
export function getProducts() {
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await api.getun('/api/v/ecom/products/');
      dispatch(slice.actions.getProductsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getBanners() {
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await api.getun('/api/v/ecom/banners/');
      dispatch(slice.actions.getBannersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getCategorymenu() {
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await api.get('/api/page/menu/');
      dispatch(slice.actions.getCategoryMenuSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getHome(store) {
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {
      let storeid = ''
      if (store) {
        storeid = `&str=${store.slug}`;
      }
      const response = await api.get(`/api/page/g/?${storeid}`)
      dispatch(slice.actions.getHomeSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// products/category/
export function getPincodes(search) {
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await api.get(`/api/region/p/?q=${search}`);
      dispatch(slice.actions.getPincodesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getStores(zipcode) {
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await api.get(`/api/business/storebypincode/?q=${zipcode}`);
      dispatch(slice.actions.getStoresSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getAllAdrress() {
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await api.get('/api/v/cart/address/list/');
      dispatch(slice.actions.getAddressSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


