import PropTypes from 'prop-types';

import { Link as RouterLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import minusFill from '@iconify/icons-eva/minus-fill';
import shoppingCart from '@iconify/icons-emojione-monotone/shopping-cart';
// material
import { Box, Card, Link, Typography, CardContent, Tooltip, Fade } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';

import { useFormik, Form, FormikProvider } from 'formik';

import { capitalCase } from 'change-case';
// routes
import { PATH_PAGE } from '../../../routes/paths';
// utils
import { fCurrency } from '../../../utils/formatNumber';


import { MIconButton, MButton } from '../../../components/@material-extend';
import Progress from "../../../components/progressbar/progress";
//---------------------------------------------

import {
  increaseQuantity,
  decreaseQuantity
} from '../../../redux/slices/product';

// ----------------------------------------------------------------------
const IncrementerStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(0.5),
  padding: theme.spacing(0.5, 0.75),
  borderRadius: theme.shape.borderRadius,
  border: `solid 1px ${theme.palette.grey[500_32]}`
}));

const ProductImgStyle = styled('img')({
  top: 0,
  padding: '10px',
  paddingTop: '40px',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',

});
//--------------------------------------------

Incrementer.propTypes = {
  available: PropTypes.number,
  quantity: PropTypes.number,
  onIncrease: PropTypes.func,
  onDecrease: PropTypes.func
};

function Incrementer({ available, quantity, onIncrease, onDecrease }) {

  return (
    <Box sx={{ width: 96, textAlign: 'right', left: 10 }}>
      <IncrementerStyle>
        <MIconButton
          size="small"
          color="inherit"
          onClick={onDecrease}
          disabled={quantity <= 1}
        >
          <Icon icon={minusFill} width={16} height={16} />
        </MIconButton>
        {quantity}
        <MIconButton
          size="small"
          color="inherit"
          onClick={onIncrease}
          disabled={quantity >= available}
        >
          <Icon icon={plusFill} width={16} height={16} />
        </MIconButton>
      </IncrementerStyle>

    </Box>
  );

};


// ----------------------------------------------------------------------


ShopProductCard.propTypes = {
  itemproduct: PropTypes.object,
  cart: PropTypes.array,
  onAddCart: PropTypes.func,
  onGotoStep: PropTypes.func,
  isSponser: PropTypes.bool
};

export default function ShopProductCard({
  itemproduct,
  cart,
  onAddCart,
  onGotoStep, ...other }) {

  const dispatch = useDispatch();

  const { desc, image, cover, ean, slug, brand, id, price, status, coopen
  } = itemproduct;

  const available = 10;
  const hasInCart = cart.filter((item) => item.product.slug === slug).map((item) => item.quantity)[0] >= 1;
  const fqty = cart.filter((item) => item.product.slug === slug).map((item) => item.quantity)[0];

  const alreadyProduct = cart.map((item) => item.product.slug).includes(slug);
  const isMaxQuantity = cart.filter((item) => item.product.slug === slug).map((item) => item.quantity)[0] >= available;
  const linkTo = `${PATH_PAGE.home}/product/${slug}`;


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id,
      desc,
      brand,
      ean,
      image,
      available,
      price,
      quantity: status !== 'out of stock' ? 0 : 1
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (!alreadyProduct) {
          onAddCart({
            ...values,
            subtotal: values.price * values.quantity
          });
        }
        setSubmitting(false);
        onGotoStep(0);
      } catch (error) {
        setSubmitting(false);
      }
    }
  });

  const { handleSubmit } = formik;

  const handleIncreaseQuantity = (product) => {
    dispatch(increaseQuantity(product));
  };

  const handleDecreaseQuantity = (product) => {
    dispatch(decreaseQuantity(product));
  };

  const handleAddCart = async () => {
    try {
      onAddCart(itemproduct);
    } catch (error) {
      console.error(error);
    }
  };


  return (
    <Card {...other}>
      <Progress data={coopen.progress} total={coopen.template.count} sold={coopen.sold} sx={{
        top: 10,
        position: 'absolute',
      }} />
      <Box sx={{ pt: '100%', position: 'relative', borderRadius: '8%' }}>

        <Link to={linkTo} color="inherit" component={RouterLink}>
          <ProductImgStyle alt={desc} src={cover} />
        </Link>
      </Box>
      <CardContent sx={{ minHeight: 200 }}>
        <Typography variant="h6">
          ₹{fCurrency(price)}
        </Typography>


        <Tooltip
          placement="top"
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
          title={capitalCase(desc)}
        >
          <Link to={linkTo} color="inherit" component={RouterLink}>
            <Typography variant="body2" noWrap>
              {capitalCase(desc)}
            </Typography>
            <Typography variant="body2" noWrap>
              {coopen.template.name}
            </Typography>
          </Link>
        </Tooltip>
        {status !== 'out of stock' ?
          <Box
            sx={{
              mt: 2,
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'space-between'
            }}
          >
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Box alignItems={'center'}>
                  {hasInCart ?
                    <Incrementer
                      quantity={Math.floor(fqty)}
                      available={available}
                      onDecrease={() => handleDecreaseQuantity(itemproduct)}
                      onIncrease={() => handleIncreaseQuantity(itemproduct)}
                    />
                    :
                    <MButton
                      fullWidth
                      disabled={isMaxQuantity}
                      size="small"
                      type="button"
                      color="primary"
                      variant="contained"
                      startIcon={<Icon icon={shoppingCart} color="white" />}
                      onClick={handleAddCart}
                      sx={{ whiteSpace: 'noWrap' }}
                    >
                      Add to Cart
                    </MButton>
                  }
                </Box>
              </Form>
            </FormikProvider>

          </Box>
          : ''}
      </CardContent>
    </Card>
  );
}