import axios from './axios';

export const api = {
    get,
    getun,
    post,
    postmulti,
    postun,
    put,
    putmulti,
    delete: _delete

};

async function get(url) {
    const token = localStorage.getItem('access_token')
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': token ? `JWT ${token}` : null
        },

    };
    const response = await axios.get(url, requestOptions);
    return response;
}

async function getun(url) {
    const requestOptions = {
        method: 'GET',
    };
    const response = await axios.get(url, requestOptions);
    return response;
}


async function post(url, body) {
    const token = localStorage.getItem('access_token')
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
            'Authorization': token ? `JWT ${token}` : null
        },
    };

    const response = await axios.post(url, body, requestOptions);
    return response;
}

async function postun(url, body) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
        },
    };

    const response = await axios.post(url, body, requestOptions);
    return response;
}

async function postmulti(url, data) {
    const token = localStorage.getItem('access_token')
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'multipart/form-data',
            accept: 'application/json',
            'Authorization': token ? `JWT ${token}` : null
        }

    };
    const response = await axios.post(url, data, requestOptions);
    return response;
}


async function put(url, data) {
    const token = localStorage.getItem('access_token')
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
            'Authorization': token ? `JWT ${token}` : null
        },

    };
    const response = await axios.put(url, data, requestOptions);
    return response;
}

async function putmulti(url, data) {
    const token = localStorage.getItem('access_token')
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'multipart/form-data',
            accept: 'application/json',
            'Authorization': token ? `JWT ${token}` : null
        }

    };
    const response = await axios.put(url, data, requestOptions);
    return response;
}


// prefixed with underscored because delete is a reserved word in javascript
async function _delete(url) {
    const token = localStorage.getItem('access_token')
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Authorization': token ? `JWT ${token}` : null
        }
    };
    const response = await axios.delete(url, requestOptions);
    return response;
}
