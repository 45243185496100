import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
// components
import Page from '../../components/Page';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: "lg",
  margin: 'auto',
  minHeight: '5vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Refund() {
  return (
    <Page title="Refund Privacy">
      <Container>
        <ContentStyle sx={{  alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
          Refund  Policy
          </Typography>

          {privacies.map((privacy, index) => (
            <Box key={index} sx={{textAlign: 'center',}}>
              <Typography variant={'h5'} sx={{ pl: 2, mt: 2, mb: 1, color: '#0E2F44', textAlign: 'center' }}>
                {privacy.title}
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>{privacy.desc}</Typography>
            </Box>
          ))}
          <Box sx={{ mb: 5 }} />
          <Button to="/" size="large" variant="contained" component={RouterLink}>
            Go to Home
          </Button>
        </ContentStyle>
      </Container>
    </Page>
  );
}

const privacies = [
  {
    title: 'Refund Policy for Garliksofts LLP',
    desc: 'Thank you for purchasing our softwares, digital products, courses and study materials at Garliksofts LLP.',
  },
  {
    title: '',
    desc: "We understand that sometimes circumstances may change, and you may need to cancel your order or request a refund. However, we would like to inform you that once you have made a purchase on our platform, we cannot provide a refund or cancel your order.",
  },
  {
    title: '',
    desc: "Please note that our softwares, courses and study materials are digital products that are available for immediate use, and once you have accessed the material, we cannot take it back. We invest significant time and resources in creating and updating our softwares, products, courses and  materials, and we hope that you will understand our position on this matter.",
  },
  {
    title: '',
    desc: "We encourage you to read the course descriptions and preview any available materials carefully before making a purchase. This will help you make an informed decision and ensure that the course or study material is suitable for your needs.",
  },
  {
    title: '',
    desc: "If you have any questions or concerns about our refund policy, please feel free to contact us at info@garliksofts.com. Our customer support team will be happy to assist you with any queries you may have." },
  {
    title: '',
    desc: "Thank you for your understanding and support.",
  },
  {
    title: '',
    desc: 'Garliksofts LLP',
  },
];
