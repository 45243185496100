import PropTypes from "prop-types";
import { capitalCase } from 'change-case';
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/styles";
// @mui
import { Card, Grid, Skeleton, Typography } from "@mui/material";
import Label from '../../../components/Label';
import Logo from '../../../components/Logo';

// --------------------------------------------

const SkeletonLoad = (
  <>
    {[...Array(12)].map((item, index) => (
      <Grid item xs={12} sm={6} md={3} key={index}>
        <Skeleton
          variant="rectangular"
          width="100%"
          sx={{ paddingTop: "115%", borderRadius: 2 }}
        />
      </Grid>
    ))}
  </>
);
// -----------------------------------------------------------------

const EmptyStyle = styled("div")(({ theme }) => ({
  background: `linear- gradient(to right, ${"#35ead2"}, ${"#ff6060"})`,
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5),
  borderRadius: theme.spacing(2),
  opacity: '0.9',
  [theme.breakpoints.up("md")]: {
    paddingBottom: theme.spacing(5),
  },
}));
// ----------------------------------------------------------------------
DiscountCard.propTypes = {
  item: PropTypes.object,
};

function DiscountCard({ item }) {
  const theme = useTheme();
  const { coopen, product, addedAt, coopencode } = item;
  const { template, drawDate } = coopen;
  return (
    <Card sx={{
      pb: 2
    }} >
      <Logo sx={{ mt: 3, mb: 1, mx: 'auto' }} />
      <Grid ml={3}>
        <Typography
          variant="h4"
          sx={{
            pb: "1em",
            left: 3,
            color: 'secondary'
          }}
        >
          {capitalCase(template.name)}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            color: 'secondary'
          }}
        >
          Product:  {capitalCase(product)}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            color: 'secondary'
          }}
        >
          Purchased On: {addedAt}
        </Typography>
        {
          coopen.status !== 5 ? <Typography
            variant="h6"
            sx={{
              color: 'secondary'
            }}
          >
            Draw On: {drawDate}
          </Typography> : <Typography
            variant="h6"
            sx={{
              color: 'secondary'
            }}
          >
            Draw On: we will update soon
          </Typography>
        }
        <Typography
          variant="h6"
          sx={{
            color: 'secondary'
          }}
        >
          Coupon No: <Label
            variant={'limited'}
            color={'info'}
            sx={{
              right: 3,
              position: 'relative',
              textTransform: 'uppercase'
            }}
          >
            {coopencode}
          </Label>
        </Typography>

      </Grid>
    </ Card >
  );
}

//---------------------------------------------------------------
ActiveCoupensList.propTypes = {
  rewards: PropTypes.array,
  isLoad: PropTypes.bool,
};

export default function ActiveCoupensList({ rewards, isLoad, ...other }) {
  const theme = useTheme();

  return (
    <Grid container spacing={theme.spacing(3)} {...other} >

      {rewards.map((reward, index) => (
        <Grid key={index} item xs={12} md={6}>
          <DiscountCard item={reward} />
        </Grid>
      ))}

      {rewards.length <= 0 ? (
        <Grid item xs={12} md={6} >
          <EmptyStyle>
            <Typography
              variant="h4"
              sx={{
                pb: "1em",
                textAlign: "center",
                textTransform: "uppercase",
                color: 'white'
              }}
            >
              No new Rewards
            </Typography>
          </EmptyStyle>
        </Grid>
      ) : (
        ""
      )}

      {isLoad && SkeletonLoad}
    </Grid>
  );
}
