import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTheme,styled } from "@mui/styles";
import { motion, useScroll } from "framer-motion"
// material
import { Grid,Stack,LinearProgress } from '@mui/material';

// hooks
import useCart from "../hooks/useCart";

// redux
import { getBanners, getProducts } from "../redux/slices/home";

// components
import Page from '../components/Page';


 import FooterContainer from "../layouts/footer/FooterContainer";
// sections

import { ScrollTop, Hero, ClientList, WorkList,Services,ContactUs } from '../sections/external2/home';
// ----------------------------------------------------------------------
// Temp Data
import { clients, services, Works } from '../sections/external2/home/data';


// ----------------------------------------------------------------------
const RootStyle = styled(Page)(({ theme }) => ({
    height: '100%',
    paddingTop:'88px',
    [theme.breakpoints.down("md")]: {
      paddingTop:'64px',
    },
  }));

// ----------------------------------------------------------------------

export default function HomePage() {
  const theme = useTheme();
  const { scrollYProgress } = useScroll();

  return (
    <>
    <ScrollTop value={scrollYProgress} />
   
    <RootStyle title="Lead in tech - " id="move_top">
       
       <Hero/>

       <ClientList clients={clients} isLoad={false}  />

       <Services data={services} isLoad={false} />

       <WorkList data={Works} isLoad={false}  />
       
       <ContactUs />

       <FooterContainer />
      
    </RootStyle >
    
    </>
  );
}
