import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../utils/apiwrapper';

//---------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  discountcoopens: [],
  timeslots: [],
  checkout: {
    activeStep: 0,
    cart: [],
    subtotal: 0,
    total: 0,
    discount: 0,
    shipping: 0,
    billing: null
  }
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.product = action.payload;
    },

    // GET DISCOUNT
    getDiscountSuccess(state, action) {
      state.isLoading = false;
      state.discountcoopens = action.payload;
    },
    // GET TIME SLOTS
    getTimeSlostSuccess(state, action) {
      state.isLoading = false;
      state.timeslots = action.payload;
    },

    // CHECKOUT 
    getCartSuccess(state, action) {
      const cart = action.payload;
      const { id, items, discount, subtotal, shipping, address, total, points, usepoints, deliveryoption, deliveryslot, activestep } = cart;

      const billing = items.length === 0 ? null : address;
      state.checkout.activeStep = activestep;
      state.checkout.cart = items;
      state.checkout.discount = discount;
      state.checkout.shipping = shipping;
      state.checkout.billing = billing;
      state.checkout.subtotal = subtotal;
      state.checkout.total = total;
      state.checkout.points = points;
      state.checkout.usepoints = usepoints;
      state.checkout.deliveryoption = deliveryoption;
      state.checkout.deliveryslot = deliveryslot;
      localStorage.setItem('cartid', id);
    },
    getCompleteSuccess(state) {
      state.checkout.activeStep = 3;
      localStorage.removeItem('cartid');
    },

    resetCartSuccess(state) {
      state.checkout.activeStep = 0;
      state.checkout.cart = [];
      state.checkout.total = 0;
      state.checkout.subtotal = 0;
      state.checkout.discount = 0;
      state.checkout.shipping = 0;
      state.checkout.points = 0;
      state.checkout.usepoints = false;
      state.checkout.billing = null;
    },

    onBackStep(state) {
      state.checkout.activeStep -= 1;
    },

    onNextStep(state) {
      state.checkout.activeStep += 1;
      console.log('step up', state.checkout.activeStep)
    },

    onGotoStep(state, action) {
      const goToStep = action.payload;
      state.checkout.activeStep = goToStep;
    },
    createBilling(state, action) {
      state.checkout.billing = action.payload;
    },


  }
});

// Reducer
export default slice.reducer;

export const {
  createBilling,
} = slice.actions;

export const {
  onGotoStep,
  onBackStep,
  onNextStep,
} = slice.actions;
// Actions

//----------------------------------------------------------------------

function getcart() {
  const cartid = localStorage.getItem('cartid')
  return cartid || '0';
}

export function getCart() {
  return async (dispatch) => {
    const body = getcart()
    const response = await api.get(`/api/v/cart/get/${body}/`);
       dispatch(slice.actions.getCartSuccess(response.data));
  };
}

export function addToCart(product) {

  return async (dispatch) => {
    const body = {
      'cartid': getcart()
    }
    const response = await api.post(`/api/v/cart/add/${product.slug}/`, body);
    dispatch(slice.actions.getCartSuccess(response.data));
  };
}

export function deleteCart(product) {
  return async (dispatch) => {
    const body = {
      'cartid': getcart()
    }
    const response = await api.post(`/api/v/cart/remove/${product.slug}/`, body);
    dispatch(slice.actions.getCartSuccess(response.data));
  };
}
export function resetCart() {
  return async (dispatch) => {
    const body = {
      'cartid': getcart()
    }
    const response = await api.post('/api/v/cart/reset/', body);
    dispatch(slice.actions.getCartSuccess(response.data));
    dispatch(slice.actions.resetCartSuccess());
    resetCart()
  };
}
export function increaseQuantity(product) {
  return async (dispatch) => {
    const body = {
      'cartid': getcart()
    }
    const response = await api.post(`/api/v/cart/increment/${product.slug}/`, body);
    dispatch(slice.actions.getCartSuccess(response.data));
  };
}

export function decreaseQuantity(product) {
  return async (dispatch) => {
    const body = {
      'cartid': getcart()
    }
    const response = await api.post(`/api/v/cart/decrease/${product.slug}/`, body);
    dispatch(slice.actions.getCartSuccess(response.data));
  };
}

export function getDiscounts() {
  return async (dispatch) => {
    const response = await api.get('/api/cart/discount/get/');
    dispatch(slice.actions.getDiscountSuccess(response.data));
  };
}

export function applyPoints() {
  return async (dispatch) => {
    const body = {
      'cartid': getcart()
    }
    const response = await api.post(`/api/v/cart/points/use/`, body);
    dispatch(slice.actions.getCartSuccess(response.data));
    dispatch(getDiscounts());
  };
}
export function removePoints() {
  return async (dispatch) => {
    const body = {
      'cartid': getcart()
    }
    const response = await api.post(`/api/v/cart/points/remove/`, body);
    dispatch(slice.actions.getCartSuccess(response.data));
    dispatch(getDiscounts());
  };
}
export function applyDiscount(discount) {
  return async (dispatch) => {
    const body = {
      'cartid': getcart()
    }
    const response = await api.post(`/api/cart/discount/add/${discount.id}/`, body);
    dispatch(slice.actions.getCartSuccess(response.data));
    dispatch(getDiscounts());
  };
}
export function removeDiscount(discount) {
  return async (dispatch) => {
    const response = await api.post(`/api/cart/discount/remove/${discount.id}/`);
    dispatch(slice.actions.getCartSuccess(response.data));
    dispatch(getDiscounts());
  };
}

export function addAddress(address) {
  return async (dispatch) => {
    const body = {
      'cartid': getcart()
    }
    const response = await api.post(`/api/cart/v/address/add/${address.id}/`, body);
    dispatch(slice.actions.getCartSuccess(response.data));
  };
}

export function addNewAddress(address) {
  return async (dispatch) => {
    const cartid = getcart();
    // eslint-disable-next-line camelcase
    const { name, phone_number, zipcode, landmark, is_default } = address;
    const formData = new FormData();
    formData.append('name', name);
    formData.append('phone', phone_number);
    formData.append('zipcode', zipcode);
    formData.append('landmark', landmark);
    formData.append('address', address.address)
    formData.append('state', 'state')
    formData.append('is_default', is_default)
    formData.append('cartid', cartid)
    console.log('printing address ');
    console.log(address)
    const response = await api.postmulti('/api/v/cart/address/new/', formData);
    dispatch(slice.actions.getCartSuccess(response.data));
  };
}

export function applyShipping(value) {
  return async (dispatch) => {
    const body = {
      'cartid': getcart()
    }
    const response = await api.post(`/api/cart/delivery/${value}/`, body);
    dispatch(slice.actions.getCartSuccess(response.data));
  };
}
export function getTimeSlots() {
  return async (dispatch) => {
    const body = {
      'cartid': getcart()
    }
    const response = await api.get('/api/cart/getslots/', body);
    dispatch(slice.actions.getTimeSlostSuccess(response.data));
  };
}
export function applyTimeSlot(value) {
  return async (dispatch) => {
    const body = {
      'cartid': getcart()
    }
    const response = await api.post(`/api/cart/deliveryslot/${value}/`, body);
    dispatch(slice.actions.getCartSuccess(response.data));
  };
}

export function CompleteOrder() {
  return async (dispatch) => {
    const response = await api.post('/api/cart/complete/');
    // eslint-disable-next-line no-bitwise
    if (response.status === 201 | response.status === 200) {
      dispatch(slice.actions.getCompleteSuccess());
    }
  };
}


export function setPaymentOption(paymentmethods) {
  return async (dispatch) => {
    const response = await api.post(`/api/cart/delivery/${paymentmethods.id}/`);
    dispatch(slice.actions.getCartSuccess(response.data));
  };
}

//----------------------------------------------------------------------

export function getProduct(slug) {
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await api.get(`api/v/ecom/product/${slug}/`)
      dispatch(slice.actions.getProductSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}