import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme, styled } from '@mui/styles';
import {Box, Grid, Skeleton, Typography } from '@mui/material';
import { MotionInView } from '../../../components/animate';
import getVariant from '../../../utils/getVariant';
// material

import { PATH_PAGE } from '../../../routes/paths';

// ----------------------------------------------------------------------

const SkeletonLoad = (
  <>
    {[...Array(12)].map((item, index) => (
      <Grid item xs={12} sm={6} md={3} key={index}>
        <Skeleton variant="rectangular" width="100%" sx={{ paddingTop: '115%', borderRadius: 2 }} />
      </Grid>
    ))}
  </>
);
// ----------------------------------------------------------------------
const WorkImgStyle = styled('img')(({ theme }) => ({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'relative',
  borderEndEndRadius: '10%',
  padding:theme.spacing(5),


}))


WorkCard.propTypes = {
  item: PropTypes.object,
};

function WorkCard({ item }) {
  const theme = useTheme();
  const { image, title, id, url } = item;
  const linkTo = `${url}`;
  return (
    <Box to={linkTo} component={RouterLink}>
      <WorkImgStyle key={id} alt={title} src={image} sx={{ cursor: 'pointer', boxShadow: theme.shadows[2] }} />
    </Box>
  );
}

WorkList.propTypes = {
  data: PropTypes.array.isRequired,
  isLoad: PropTypes.bool,
  other: PropTypes.object,
};

export default function WorkList({ data, isLoad, ...other }) {
  const theme = useTheme();

  return (
    <Grid container spacing={theme.spacing(0)} {...other}>
      <Grid item xs={12} mx={theme.spacing(20)} mt={theme.spacing(5)} mb={theme.spacing(5)}>
        <Typography variant={'h5'} sx={{ pl: 2, mt: 2, mb: 1, color:"#e489",  textAlign: 'center' }}>
          RECENT WORKS
        </Typography>
        <Typography variant={'h4'} sx={{ pl: 2, mt: 2, }}>
          At Garliksofts Solutions, we care about your business and needs. That is why our team is selective about our products. 

           </Typography>
      </Grid>
      {data.map((work, index) => (
        <Grid key={index} item xs={12} md={4} >
          
            <WorkCard item={work} />
        </Grid>
      ))}
      {isLoad && SkeletonLoad}
    </Grid>
  );
}
