import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@mui/material/styles';
import { Box, Button, Container, Typography } from '@mui/material';
// routes
import { PATH_AUTH } from '../../routes/paths';
// components
import Logo from '../../components/Logo';
import Page from '../../components/Page';
import { ResetPasswordForm, ResetPasswordChangeForm } from '../../sections/auth/reset-password';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5)
  }
}));

// ----------------------------------------------------------------------

export default function ResetPassword() {
  const [vid, setVerificationcode] = useState('');
  const [sent, setSent] = useState(false);

  return (
    <RootStyle title="Reset Password">
      <HeaderStyle>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
      </HeaderStyle>

      <Container>
        <Box sx={{ maxWidth: 480, mx: 'auto' }}>
          <Typography variant="h3" gutterBottom>
            Forgot your password?
          </Typography>
          {!sent ? (
            <>

              <Typography sx={{ color: 'text.secondary', mb: 5 }}>
                Please enter the phone number associated with your account and
                We will send a otp to you a link to reset your password.
              </Typography>

              <ResetPasswordForm
                onSent={() => setSent(true)}
                onGetVid={(value) => setVerificationcode(value)}
              />

              <Button
                fullWidth
                size="large"
                component={RouterLink}
                to={PATH_AUTH.login}
                sx={{ mt: 1 }}
              >
                Back
              </Button>
            </>
          ) : (
            <>
              <Typography sx={{ color: 'text.secondary', mb: 5 }}>
                Please enter the password and verification code.
              </Typography>
              <ResetPasswordChangeForm
                onNotSent={() => setSent(false)}
                vid={vid}
              />

              <Button
                fullWidth
                size="large"
                component={RouterLink}
                to={PATH_AUTH.login}
                sx={{ mt: 1 }}
              >
                Back
              </Button>

            </>)}
        </Box>
      </Container>
    </RootStyle>
  );
}
