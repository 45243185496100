import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import editFill from '@iconify/icons-eva/edit-fill';
import plusFill from '@iconify/icons-eva/plus-fill';
import minusFill from '@iconify/icons-eva/minus-fill';
// material
import { experimentalStyled as styled } from '@mui/material/styles';
import {
  Box,
  Card,
  Button,
  Divider,
  CardHeader,
  Typography,
  CardContent
} from '@mui/material';
// utils
import { fCurrency } from '../../../utils/formatNumber';
// ----------------------------------------------------------------------

const RowStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  '&:not(:last-child)': {
    marginBottom: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

CheckoutSummary.propTypes = {
  total: PropTypes.number,
  discount: PropTypes.number,
  subtotal: PropTypes.number,
  shipping: PropTypes.number,
  onEdit: PropTypes.func,
  enableEdit: PropTypes.bool,
  onApplyPoints: PropTypes.func,
  onRemovePoints: PropTypes.func,
  enableDiscount: PropTypes.bool,
  sx: PropTypes.object
};

export default function CheckoutSummary({
  total,
  onEdit,
  discount,
  subtotal,
  shipping,
  points,
  usepoints,
  activepoints,
  onApplyPoints,
  onRemovePoints,
  enableEdit = false,
  enableDiscount = false,
  discountcoopens,
  sx
}) {
  const displayShipping = shipping === 0 ? 'Free' : shipping;

  return (
    <Card sx={{ mb: 3, ...sx }}>
      <CardHeader
        title="Order Summary"
        action={
          enableEdit && (
            <Button
              size="small"
              type="button"
              onClick={onEdit}
              startIcon={<Icon icon={editFill} />}
            >
              Edit
            </Button>
          )
        }
      />

      <CardContent>


        <RowStyle>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Sub Total
          </Typography>
          <Typography variant="subtitle2">{fCurrency(subtotal)}</Typography>
        </RowStyle>

        <RowStyle>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Discount
          </Typography>
          <Typography variant="subtitle2">
            {discount ? fCurrency(-discount) : '-'}
          </Typography>
        </RowStyle>

        <RowStyle>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Shipping
          </Typography>
          <Typography variant="subtitle2">
            {shipping > 0 ? fCurrency(shipping) : displayShipping}
          </Typography>
        </RowStyle>
        <RowStyle>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>

            {activepoints &&
              < Button
                size="medium"
                type="button"
                variant="outlined"
                mr={1}
                onClick={usepoints ? onRemovePoints : onApplyPoints}
                startIcon={<Icon icon={usepoints ? minusFill : plusFill} />}
              >
                {usepoints ? ' Points' : ' Points'}
              </Button>} Points Used
          </Typography>
          <Typography variant="subtitle2">
            {points}
          </Typography>
        </RowStyle>
        <RowStyle>
          <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
            Total
          </Typography>
          <Typography variant="subtitle1" sx={{ color: 'error.main' }}>
            {fCurrency(total)}
          </Typography>
        </RowStyle>

        <Divider sx={{ mb: 2 }} />

        <RowStyle>
          <Typography variant="subtitle1">Payable</Typography>
          <Box sx={{ textAlign: 'right' }}>
            <Typography variant="subtitle1" sx={{ color: 'error.main' }}>
              {fCurrency(total - points)}
            </Typography>
            <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
              (All Taxes included)
            </Typography>
          </Box>
        </RowStyle>

      </CardContent>
    </Card >
  );
}
