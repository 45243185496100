import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import userReducer from './slices/user';
import authJwtReducer from './slices/authJwt';
import settingsReducer from './slices/settings';
import homeReducer from './slices/home';
import productReducer from './slices/product';
import cmsReducer from './slices/cms';
import shopReducer from './slices/shop';
import orderReducer from './slices/order'
import tagReducer from './slices/tags';
import pointReducer from './slices/points';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['settings']
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const authPersistConfig = {
  key: 'authJwt',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['isAuthenticated', 'user']
};

const rootReducer = combineReducers({
  user: userReducer,
  settings: settingsReducer,
  home: homeReducer,
  product: persistReducer(productPersistConfig, productReducer),
  cms: cmsReducer,
  shop: shopReducer,
  order: orderReducer,
  tags: tagReducer,
  points: pointReducer,
  authJwt: persistReducer(authPersistConfig, authJwtReducer)
});

export { rootPersistConfig, rootReducer };
