import jwtDecode from 'jwt-decode';
import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../utils/apiwrapper';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  cstore: null,
  user: null
};

const slice = createSlice({
  name: 'authJwt',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // INITIALISE
    getInitialize(state, action) {
      state.isLoading = false;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user = action.payload.user;
      state.cstore = action.payload.cstore;
    },

    // LOGIN
    loginSuccess(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload.user;
    },

    // REGISTER
    registerSuccess(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload.user;
    },

    // LOGOUT
    logoutSuccess(state) {
      state.isAuthenticated = false;
      state.user = null;
      state.cstore = null;
    },
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (access, refresh) => {
  if (access) {
    localStorage.setItem('access_token', access);
    localStorage.setItem('refresh_token', refresh);


  } else {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
  }
};


// ----------------------------------------------------------------------

export function login(username, password) {
  return async (dispatch) => {
    const body = JSON.stringify({ username, password });
    const response = await api.postun('/api/v/auth/token/', body);
    const { access, refresh, user } = response.data;
    setSession(access, refresh);
    dispatch(slice.actions.loginSuccess({ user }));
  };
}

// ----------------------------------------------------------------------

export function register({ email, password, firstName, lastName }) {
  return async (dispatch) => {
    const response = await api.postun('/api/account/register/', {
      email,
      password,
      firstName,
      lastName
    });
    const { access, refresh, user, cstore } = response.data;
    setSession(access, refresh);
    dispatch(slice.actions.storeSuccess({ cstore }));
    dispatch(slice.actions.registerSuccess({ user }));
  };
}

// ----------------------------------------------------------------------

export function logout() {
  return async (dispatch) => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    dispatch(slice.actions.logoutSuccess());
  };
}

export function updateProfile({ values }) {
  console.log('update profile');
  return async (dispatch) => {
    const body = JSON.stringify({ values });
    console.log(body);
    const response = await api.post('/token', body);
    const { user } = response.data;
    dispatch(slice.actions.loginSuccess({ user }));
  };
}


// ----------------------------------------------------------------------

export function getInitialize() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const accessToken = window.localStorage.getItem('access_token');
      const refreshToken = window.localStorage.getItem('refresh_token');

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken, refreshToken);

        const response = await api.get('/api/v/auth/profile/');
        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: true,
            user: response.data,
          })
        );
      } else {
        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: false,
            user: null
          })
        );
      }
    } catch (error) {
      console.error(error);
      dispatch(
        slice.actions.getInitialize({
          isAuthenticated: false,
          user: null
        })
      );
    }
  };
}
