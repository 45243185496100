import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import minusFill from '@iconify/icons-eva/minus-fill';
// material
import { Box, Card, Grid, Link, Typography, Tooltip, Fade } from '@mui/material';
import shoppingCart from '@iconify/icons-emojione-monotone/shopping-cart';
import { useTheme, experimentalStyled as styled } from '@mui/material/styles';
import { useFormik, Form, FormikProvider } from 'formik';
import { capitalCase } from 'change-case';
// routes
import { PATH_PAGE } from '../../../routes/paths';
// utils
import { fCurrency } from '../../../utils/formatNumber';
import { fDate } from '../../../utils/formatTime';

//
import Label from '../../../components/Label';

import { MIconButton, MButton } from '../../../components/@material-extend';
import Progress from "../../../components/progressbar/progress";
//---------------------------------------------

import {
    increaseQuantity,
    decreaseQuantity
} from '../../../redux/slices/product';

// ----------------------------------------------------------------------
const IncrementerStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(0.5),
    padding: theme.spacing(0.5, 0.75),
    borderRadius: theme.shape.borderRadius,
    border: `solid 1px ${theme.palette.grey[500_32]}`
}));

const ThumbPrizeImgStyle = styled('img')(({ theme }) => ({
    marginLeft: 10,
    width: 300,
    height: 200,
    minWidth: 150,
    objectFit: 'cover',
    marginRight: theme.spacing(2),
    borderRadius: theme.shape.borderRadiusSm
}));

const ThumbProductImgStyle = styled('img')(({ theme }) => ({
    marginLeft: 10,
    width: 80,
    height: 80,
    objectFit: 'cover',
    marginRight: theme.spacing(2),
    borderRadius: theme.shape.borderRadiusSm
}));

// --------------------------------

Incrementer.propTypes = {
    available: PropTypes.number,
    quantity: PropTypes.number,
    onIncrease: PropTypes.func,
    onDecrease: PropTypes.func
};
function Incrementer({ available, quantity, onIncrease, onDecrease }) {

    return (
        <Box sx={{ width: 96, textAlign: 'right', left: 10 }}>
            <IncrementerStyle>
                <MIconButton
                    size="small"
                    color="inherit"
                    onClick={onDecrease}
                    disabled={quantity <= 1}
                >
                    <Icon icon={minusFill} width={16} height={16} />
                </MIconButton>
                {quantity}
                <MIconButton
                    size="small"
                    color="inherit"
                    onClick={onIncrease}
                    disabled={quantity >= available}
                >
                    <Icon icon={plusFill} width={16} height={16} />
                </MIconButton>
            </IncrementerStyle>

        </Box>
    );

};

// ----------------------------------------------------------------------


VerticalProductCard.propTypes = {
    product: PropTypes.object,
    cart: PropTypes.array,
    onAddCart: PropTypes.func,
    onGotoStep: PropTypes.func,
    isSponser: PropTypes.bool
};

export default function VerticalProductCard({
    product,
    cart,
    onAddCart,
    onGotoStep, ...other }) {
    const theme = useTheme();
    const dispatch = useDispatch();

    const { desc, image, ean, slug, brand, price, status, coopen
    } = product;


    const available = 10;
    console.log(cart)
    const hasInCart = cart.filter((item) => item.product.slug === slug).map((item) => item.quantity)[0] >= 1;
    const fqty = cart.filter((item) => item.product.slug === slug).map((item) => item.quantity)[0];

    // const {  id,name,sizes, cover, price, colors, status, priceSale,available } = product;
    const alreadyProduct = cart.map((item) => item.product.slug).includes(slug);
    const isMaxQuantity = cart.filter((item) => item.product.slug === slug).map((item) => item.quantity)[0] >= available;
    const linkTo = `${PATH_PAGE.home}/product/${slug}`;


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            slug,
            desc,
            brand,
            ean,
            image,
            available,
            price,
            quantity: status !== 'out of stock' ? 0 : 1
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                if (!alreadyProduct) {
                    onAddCart({
                        ...values,
                        subtotal: values.price * values.quantity
                    });
                }
                setSubmitting(false);
                onGotoStep(0);
            } catch (error) {
                setSubmitting(false);
            }
        }
    });

    const { handleSubmit } = formik;


    const handleIncreaseQuantity = (product) => {
        dispatch(increaseQuantity(product));
    };

    const handleDecreaseQuantity = (product) => {
        dispatch(decreaseQuantity(product));
    };

    const handleAddCart = async () => {
        try {
            onAddCart(product);
        } catch (error) {
            console.error(error);
        }
    };

    function renderStatus() {
        return (
            <>
                {status && (
                    <Label
                        variant={'limited'}
                        color={(status === 'out of stock' && 'error') || (status === 'low stock' && 'warning') || 'info'}
                        sx={{
                            right: 3,
                            position: 'relative',
                            textTransform: 'uppercase'
                        }}
                    >
                        {status}
                    </Label>
                )
                }
            </>
        );
    }
    function renderprice() {
        return (
            <Typography variant="h6" sx={{ mb: 1 }}>


                ₹{fCurrency(price)}
            </Typography>

        );
    }
    function renderDate() {
        return (
            <Box sx={{
                bottom: 50,
                right: 10,
                position: 'absolute',
            }}>
                <Typography variant="caption" sx={{ mb: 1 }}>
                    Date:{fDate(coopen.drawdate)}
                </Typography>
            </Box>
        );
    }

    function renderIncrementer() {

        return (
            <Box sx={{
                bottom: 10,
                right: 10,
                position: 'absolute',
                textTransform: 'uppercase'
            }}>
                {status !== 'out of stock' ?
                    <Box
                        sx={{
                            mt: 2,
                            display: 'flex',
                            alignItems: 'flex-end',
                            justifyContent: 'space-between'
                        }}
                    >
                        <FormikProvider value={formik}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                <Box alignItems={'center'}>
                                    {hasInCart ?
                                        <Incrementer
                                            quantity={Math.floor(fqty)}
                                            available={available}
                                            onDecrease={() => handleDecreaseQuantity(product)}
                                            onIncrease={() => handleIncreaseQuantity(product)} />
                                        :
                                        <MButton
                                            fullWidth
                                            disabled={isMaxQuantity}
                                            size="small"
                                            type="button"
                                            color="primary"
                                            variant="contained"
                                            startIcon={<Icon icon={shoppingCart} />}
                                            onClick={handleAddCart}
                                            sx={{ whiteSpace: 'noWrap' }}
                                        >
                                            Add to Cart
                                        </MButton>}
                                </Box>
                            </Form>
                        </FormikProvider>

                    </Box>
                    : ''
                }
            </Box >);
    }


    return (
        <Card {...other} sx={{ display: 'flex', py: 4, boxShadow: theme.spacing(1) }}>

            <Grid container sx={{ alignItems: 'center' }}>
                <Grid item xs={12} md={6} px={theme.spacing(1)}>
                    <ThumbPrizeImgStyle alt={desc} src={coopen.template.image} />
                </Grid>
                <Grid item xs={12} md={6} px={theme.spacing(1)}>
                    <Progress data={coopen.progress} total={coopen.template.count} sold={coopen.sold} />

                    <Box alignItems='right'>
                        <Typography
                            component="h1"
                            variant="h1"
                            sx={{ color: '#f53659' }}
                        >
                            Buy
                        </Typography>


                        <Tooltip
                            placement="top"
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            title={capitalCase(desc)}
                        >
                            <Link to={linkTo} color="inherit" component={RouterLink}>
                                <Typography variant="h4" noWrap>
                                    {capitalCase(desc)}
                                    <Typography variant="subtitle2" noWrap>
                                        Giveaway: {coopen.template.name}
                                    </Typography>
                                </Typography>
                            </Link>
                        </Tooltip>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mt: 3,
                            }}
                        >

                            <ThumbProductImgStyle alt={desc} src={image} />


                            {/*
              <Divider
                orientation="vertical"
                sx={{ mx: 1, height: 16 }}
              />
               <Typography variant="body2">
              <Typography
                component="span"
                variant="body2"
                sx={{ color: 'text.secondary' }}
              >
                brand:&nbsp;
              </Typography>
              {brand ? brand.name : ''}
            </Typography> */}
                        </Box>
                        {renderprice()}
                    </Box>
                </Grid>

                <Grid item xs={12} md={6} px={theme.spacing(1)}>
                    {renderDate()}
                    {renderIncrementer()}
                </Grid>

            </Grid>
        </Card >
    );
}