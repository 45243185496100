import PropTypes from 'prop-types';
// material
import { useTheme, experimentalStyled as styled } from '@mui/material/styles';
import { Skeleton, Grid, Box, Link } from '@mui/material';

// ----------------------------------------------------------------------

const SkeletonLoad = (
    <>
        {[...Array(2)].map((item, index) => (
            <Grid item xs={12} md={6} lg={6} py={5} key={index}>
                <Skeleton
                    variant="rectangular"
                    width="100%"
                    sx={{ paddingTop: '115%', borderRadius: 2 }}
                />
            </Grid>
        ))}
    </>
);
// -----------------------------------------------------------
const LargeImgStyle = styled('img')({
    top: 0,
    width: '100%',
    height: Window.height,
    objectFit: 'fill',
    position: 'absolute'
});

BannerImage.propTypes = {
    item: PropTypes.object
};

function BannerImage({ item }) {
    const { image, alt, url } = item;

    return (
        <Box
            sx={{
                position: 'relative',
                cursor: 'pointer',
                paddingTop: {
                    xs: '25%',
                    md: '27%'
                }
            }}
        >
            <Link href={url} variant="inherit">
                <LargeImgStyle alt={alt} src={image} />
            </Link>
        </Box>
    );
}
// -----------------------------------------------------------
BannerList.propTypes = {
    banners: PropTypes.array.isRequired,
    isLoad: PropTypes.bool
};

export default function BannerList({ banners, isLoad, ...other }) {
    const theme = useTheme();
    if (isLoad) {
        return (<> {
            isLoad && SkeletonLoad
        }</>);
    }
    return (
        <Grid container spacing={theme.spacing(3)} pt={theme.spacing(3)} {...other} >
            {
                banners.map((banner, index) => (
                    < Grid key={index} item xs={12} md={6} lg={6} py={5}  >
                        <BannerImage item={banner} />
                    </Grid>
                ))
            }

            {isLoad && SkeletonLoad}
        </ Grid >
    );
}
