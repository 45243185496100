import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();

  const themeMode = theme.palette.mode;

  const logo = (
    <Box
      component="img"
      alt="logo"
      src={themeMode === 'light' ? "/static/garlik/garliksofts.png" : "/static/garlik/garliksoftsw.png"}
      height={40}
      {...sx}
    />
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
