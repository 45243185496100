export const clients = [
  { id: 1, title: 'English Winglish', image: '/static/garlik/englishwinglish.png' },
  
  { id: 2, title: 'ASAP UAE', image: '/static/garlik/asap.png' },
  { id: 3, title: 'Dealup', image: '/static/garlik/dealup.png' },
  { id: 4, title: 'JAss Health Care', image: '/static/garlik/jass.png' },
  { id: 5, title: 'Yesq Cart', image: '/static/garlik/yesqcart.png' },
  { id: 6, title: 'GRAF Technologies', image: '/static/garlik/graf.png' },
  { id: 7, title: 'Padicholim', image: '/static/garlik/padi.png' },
  
];

export const banners = [
  { id: 1, title: 'Banner 1', image: 'url' },
  { id: 2, title: 'Banner 2', image: 'url' },
  { id: 3, title: 'Banner 3', image: 'url' },
];

export const Works = [
  {
    id: 1,
    title: 'WORK 1',
    image: '/static/garlik/work-1.png',
    url:'#',
  },
  {
    id: 2,
    title: 'WORK 2',
    image: '/static/garlik/work-2.png',
    url:'#',
  },
  {
    id: 3,
    title: 'WORK 3',
    image: '/static/garlik/work-3.png',
    url:'#',
  },
  {
    id: 4,
    title: 'WORK 4',
    image: '/static/garlik/work-4.png',
    url:'#',
  },
  {
    id: 5,
    title: 'WORK 5',
    image: '/static/garlik/work-5.png',
    url:'#',
  },
];

export const services = [
  {
    id: 1,
    title: 'Design',
    image: '/static/garlik/service-01.png' ,
    desc: 'Because we understand the journey you’re taking, we know how to simplify it. At each stage, we give you clear goals and show you only what you need to see.',
    jobs:[
      "UI Design",
      "UX Design",
      "UX Consulting",
      "PROTOTYPE",
      "Animation",
    
    ]
  },
  {
    id: 2,
    title: 'Develop',
    image: '/static/garlik/service-02.png' ,
    desc: 'Working alongside our developers are experts in creating high-quality content and user experiences. You will receive all the benefits of our team’s expertise in a App  designed and developed to grab your attention, keep you engaged, and deepen your understanding.',
    jobs:[
      "WEB APPS",
      "Mobile APPS",
      "DESKTOP APPS",
      "VIRTUAL Reality",
      "ARGUMENT Reality",
      "WEB DESIGN",
      "SOFTWARES"
    
    ]
  },
  {
    id: 3,
    title: 'Business',
    image: '/static/garlik/service-03.png' ,
    desc: 'Garliksofts team is composed of educators, content creators, creatives, designers, developers, multimedia engineers, and more. We’ve united to provide you with an exceptional experience.',
    jobs:[
      "BRANDING",
      "STATERGY",
      "BUSINESS GUIDELINES",
      "DIGITAL MARKETING",
      "SEO",
      "BUSINESS SERVICES",
      "ACCOUNTING SERVICES"
    
    ]
  },
];


