import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme, styled } from '@mui/styles';
import { Box, Container, Grid, Skeleton, Typography } from '@mui/material';
import getVariant from '../../../utils/getVariant';
import { MHidden } from '../../../components/@material-extend';
//
import { varFadeInUp, MotionInView, varFadeInDown } from '../../../components/animate';
// material
// ----------------------------------------------------------------------

const SkeletonLoad = (
  <>
    {[...Array(12)].map((item, index) => (
      <Grid item xs={12} sm={6} md={3} key={index}>
        <Skeleton variant="rectangular" width="100%" sx={{ paddingTop: '115%', borderRadius: 2 }} />
      </Grid>
    ))}
  </>
);
// ----------------------------------------------------------------------

const ServiceImgStyle = styled('img')(({ theme }) => ({
  top: 0,
  paddingTop: theme.spacing(12),
  width: '100%',
  height: '100%',
  objectFit: 'fill',
  position: 'relative',
}));

ServiceCard.propTypes = {
  item: PropTypes.object,
  count: PropTypes.number,
};

function ServiceCard({ item, count }) {
  const theme = useTheme();
  const { image, title, jobs, desc } = item;
  const linkTo = '#'; // `${PATH_PAGE.root}/brand/${slug}`;

  function contentEnd() {
    return (
      <>
        <MotionInView variants={varFadeInUp}>
          <Typography variant="h2" sx={{ textAlign: 'center', mb: 5 }}>
            {title}
          </Typography>
        </MotionInView>
        <Box sx={{ bottom: '20px', justifyContent: 'space-around' }}>
          <Typography
            variant="h5"
            sx={{
            
              textJustify: 'inter-character',
              color: (theme) => (theme.palette.mode === 'light' ? 'text.secondary' : 'common.white'),
            }}
          >
            {desc}
          </Typography>
          <Box mb={6}/>
          {jobs.map((job, index) => (
            <Box key={index}>
              <Typography
                variant="h5"
                sx={{
                  textJustify: 'inter-character',
                  color: (theme) => (theme.palette.mode === 'light' ? 'text.secondary' : 'common.white'),
                }}
              >
                {job}
              </Typography>
            </Box>
          ))}
        </Box>
      </>
    );
  }

  function imageEnd() {
    return(
    <MotionInView variants={varFadeInDown}>
      <ServiceImgStyle alt={title} src={image} />
    </MotionInView>);
    
  }

  if (count % 2 === 0) {
    return (
      <Container maxWidth="lg" >
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={6} sx={{ pb: 5 }}>
            {contentEnd()}
          </Grid>
         
            <Grid item xs={12} md={6}>
              {imageEnd()}
            </Grid>
          
        </Grid>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" >
      <Grid container spacing={2} justifyContent="center" sx={{padding:"25px"}} >
        <MHidden width="mdDown">
          <Grid item xs={12} md={6}>
            {imageEnd()}
          </Grid>
        </MHidden>
        <Grid item xs={12} md={6} sx={{ pb: 5 }}>
          {contentEnd()}
        </Grid>
      </Grid>
    </Container>
  );
}

// ----------------------------------------------------------------------
Services.propTypes = {
  data: PropTypes.array.isRequired,
  isLoad: PropTypes.bool,
  other: PropTypes.object,
};

export default function Services({ data, isLoad, ...other }) {
  const theme = useTheme();

  return (
    <Grid container spacing={theme.spacing(1)} {...other}>
      <Grid item xs={12} mx={theme.spacing(20)} mt={theme.spacing(5)} mb={theme.spacing(20)}>
        <Typography variant={'h5'} sx={{ pl: 2, mt: 2, mb: 1, color:"#e489",  textAlign: 'center' }}>
          WHO WE ARE
        </Typography>
        <Typography variant={'h2'} sx={{ pl: 2, mt: 2, mb: 1 }}>
          We are a global creative agency that combines design expertise with technology and intelligence to
          revolutionize your business.
        </Typography>
      </Grid>
      {data.map((service, index) => (
        <Grid key={index} item xs={12} mb={theme.spacing(20)}>
          <MotionInView key={index} variants={getVariant('slideInUp')}>
            <ServiceCard item={service} count={index} />
          </MotionInView>
        </Grid>
      ))}
      {isLoad && SkeletonLoad}
    </Grid>
  );
}
