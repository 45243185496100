import PropTypes from 'prop-types';
// material
import { Skeleton, Grid } from '@mui/material';
import VerticalProductCard from './VerticalProductCard';

// ----------------------------------------------------------------------

const SkeletonLoad = (
  <>
    {[...Array(5)].map((item, index) => (
      <Grid item xs={6} md={4} lg={2} py={5} key={index}>
        <Skeleton
          variant="rectangular"
          width="100%"
          sx={{ paddingTop: '115%', borderRadius: 2 }}
        />
      </Grid>
    ))}
  </>
);

VerticalCardList.propTypes = {
  products: PropTypes.array.isRequired,
  cart: PropTypes.array,
  onAddCart: PropTypes.func,
  isLoad: PropTypes.bool,
  isSponser: PropTypes.bool
};

export default function VerticalCardList({ products, isLoad, cart, onAddCart, isSponser, ...other }) {


  if (!products) {
    return null;
  }
  return (
    <Grid container spacing={3} {...other}>
      {products.map((product, index) => (
        < Grid key={index} item xs={12} md={4} lg={3} py={5} >
          <VerticalProductCard itemproduct={product} cart={cart} onAddCart={onAddCart} isSponser={isSponser} {...other} />
        </Grid>
      ))
      }

      {isLoad && SkeletonLoad}
    </Grid >
  );
}
