import { PersistGate } from 'redux-persist/lib/integration/react';

// routes
import Router from './routes';
import { persistor } from './redux/store';

// hooks
// import useAuth from './hooks/useAuth';

// theme
import ThemeProvider from './theme';
// components
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';

import LoadingScreen from './components/LoadingScreen';
import Settings from './components/settings';
import ScrollToTop from './components/ScrollToTop';
import GoogleAnalytics from './components/GoogleAnalytics';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import ThemeLocalization from './components/ThemeLocalization';
import RtlLayout from './components/RtlLayout';
import NotistackProvider from './components/NotistackProvider';

import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';

// ----------------------------------------------------------------------

export default function App() {
  // const { isInitialized } = useAuth();
  return (
    <PersistGate loading={<LoadingScreen />} persistor={persistor}>
      <ThemeProvider>
        <ThemePrimaryColor>
          <ThemeLocalization>
            <CollapseDrawerProvider>
              <RtlLayout>
                <NotistackProvider>
                  {/* <Settings /> */}
                  <ScrollToTop />
                  <BaseOptionChartStyle />
                  <GoogleAnalytics />
                  <Router />
                </NotistackProvider>
              </RtlLayout>
            </CollapseDrawerProvider>
          </ThemeLocalization>
        </ThemePrimaryColor>
      </ThemeProvider>
    </PersistGate>
  );
}
