import PropTypes from "prop-types";
// material
import { Grid, Skeleton } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import PendingOrderCard from "./PendingOrderCard";

//---------------------------------------------

const SkeletonLoad = (
  <>
    {[...Array(12)].map((item, index) => (
      <Grid item xs={12} sm={6} md={3} key={index}>
        <Skeleton
          variant="rectangular"
          width="100%"
          sx={{ paddingTop: "115%", borderRadius: 2 }}
        />
      </Grid>
    ))}
  </>
);

// ----------------------------------------------------------------------


PendingOrders.propTypes = {
  orders: PropTypes.array,
  isLoad: PropTypes.bool,
};

export default function PendingOrders({ orders, isLoad, ...other }) {
  const theme = useTheme();

  return (
    <Grid container spacing={theme.spacing(1)} {...other}>
      {orders.map((order) => (
        <Grid key={order.id} item xs={12} md={6}>
          <PendingOrderCard order={order} />
        </Grid>
      ))}

      {isLoad && SkeletonLoad}
    </Grid>
  );
}
