// material
import { styled } from '@mui/styles';
import { Box, Grid, Container, Typography, Link } from '@mui/material';
import { MHidden } from '../../../components/@material-extend';
//
import { varFadeInUp, MotionInView, varFadeInDown } from '../../../components/animate';

import HeroAnimation from './heroanimation';

const LargeImgStyle = styled('img')(({ theme }) => ({
  top: '135px',
  width: '100%',
  height: '100%',
  objectFit: 'fill',
  position: 'absolute',
  [theme.breakpoints.down('md')]: {
    bottom: 0,
  },
}));

const RootStyle = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(15),
  minHeight: '600px',
  color: theme.palette.background,
  [theme.breakpoints.up('md')]: {
    paddingBottom: theme.spacing(15),
  },
}));

//  background: `linear-gradient( 270.97deg, #FFE580 -21.36%, #FF7571 -2.45%, #EA5DAD 26.84%, #C2A0FD 64.15%, #3BF0E4 108.29%,#B2F4B6 159.03% )`,

// ----------------------------------------------------------------------

export default function Hero() {
  const bgimg = '/static/mock-images/covers/cover_1.jpg';

  return (
    <RootStyle>
      <Container maxWidth="lg">
        <Grid container spacing={1} justifyContent="center">
          <MHidden width="mdDown">
            <Grid item xs={12} md={6}>
              <MotionInView variants={varFadeInDown}>
                <Box
                  sx={{
                    position: 'relative',
                    cursor: 'pointer',
                    paddingTop: '22%',
                  }}
                >
                  <HeroAnimation />
                </Box>
              </MotionInView>
            </Grid>
          </MHidden>
          <Grid item xs={12} md={6} sx={{ pb: 1 }}>
            <MotionInView variants={varFadeInUp}>
              <Typography variant="h1" sx={{ textAlign: 'center', justifyContent: 'space-around', mb: 5 }}>
                Innovation. Solutions
              </Typography>
              <Typography variant="h2" sx={{ textAlign: 'center', justifyContent: 'space-around', mb: 5 }}>
                You’ve got experience on your side.
              </Typography>
            </MotionInView>
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}
