import PropTypes from 'prop-types';
import { useTheme } from '@mui/styles';
import { Box, Container, Grid,  Typography } from '@mui/material';
import { MHidden } from '../../../components/@material-extend';
//
import { varFadeInUp, MotionInView, varFadeInDown } from '../../../components/animate';

import ContactForm from './contactform';
// material
// ----------------------------------------------------------------------


// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
ContactUs.propTypes = {
  other: PropTypes.object,
};

export default function ContactUs({ ...other }) {
  const theme = useTheme();

  return (
    <Grid container spacing={theme.spacing(2)} py={theme.spacing(10)}  {...other} sx={{ background: 'linear-gradient(to right bottom, #ef233c, #118ab2)',}}>
      <Container maxWidth="lg">
        <Grid container spacing={2} justifyContent="center" sx={{ color:"#edf2f4"}} >
          <Grid item xs={12} md={6} sx={{ pb: 5,}}>
            <MotionInView variants={varFadeInUp}>
              <Typography variant="h2" sx={{ textAlign: 'center', mb: 5 }}>
                Reach Us
              </Typography>
            </MotionInView>

            <Box sx={{ bottom: '20px', justifyContent: 'space-around' }}>
             
              <Typography variant="h6" sx={{pt:5, mx: 5 }}>
                Garliksofts LLP
              </Typography>
              <Typography variant="h6" sx={{pt:5, mx: 5 }}>
              BUILDING NO.7/307, OPPOSITE SYCON GLAZING PAVERS AMBALAVATOM ROAD, PARAMBILANGADI MALAPPURAM Malappuram KL 676501 IN
              </Typography>
              
              <Typography variant="h6" sx={{pt:5, mx: 5 }}>
                garliksofts@gmail.com
              </Typography>
              <Typography variant="h6" sx={{ mx: 5 }}>
                info@garliksofts.com
              </Typography>
              <Typography variant="h6" sx={{  mx: 5 }}>
                (IND) +91 9995 076 370
              </Typography>
            </Box>
          </Grid>
          <MHidden width="mdDown">
            <Grid item xs={12} md={6} >
              <MotionInView variants={varFadeInDown}>
                <ContactForm />
              </MotionInView>
            </Grid>
          </MHidden>
        </Grid>
      </Container>
    </Grid>
  );
}
