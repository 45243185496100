// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [

  {
    title: 'login',
    path: '/auth/login',
    icon: getIcon('eva:lock-fill'),
  },
  {
    title: 'register',
    path: '/auth/register',
    icon: getIcon('eva:person-add-fill'),
  },

];

export default navConfig;
