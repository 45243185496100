// component
import Iconify from '../../components/Iconify';
import { PATH_PAGE, PATH_DASHBOARD } from '../../routes/paths';
// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [

  {
    title: 'about us',
    path: PATH_PAGE.about,
    icon: getIcon('akar-icons:info-fill'),
  },
  {
    title: 'contact us',
    path: PATH_PAGE.contact,
    icon: getIcon('ant-design:message-filled'),
  },
  {
    title: 'faqs',
    path: PATH_PAGE.faqs,
    icon: getIcon('wpf:faq'),
  },
  {
    title: 'blog',
    path: '/dashboard/blog',
    icon: getIcon('eva:file-text-fill'),
  },
];

export default navConfig;
