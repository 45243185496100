import { createSlice } from '@reduxjs/toolkit';
// utils
import {api} from '../../utils/apiwrapper';

const initialState = {
  isLoading: false,
  error: false,
  faq:'',
  faqs:[],
  cmscontent:[],
  privacy:[],
  terms:[],
  storeterms:[],
  storemanual:[],
  vendorterms:[],
  vendormanual:[],
  agentterms:[],
  agentmanual:[],

};

const slice = createSlice({
  name: 'cms',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET FAQ
    getFaqSuccess(state, action) {
      state.isLoading = false;
      state.faq = action.payload;
    },
    // GET FAQs
    getFaqsSuccess(state, action) {
      state.isLoading = false;
      state.faqs = action.payload;
    },
    // GET Content
    getContentSuccess(state, action) {
      state.isLoading = false;
      state.cmscontent = action.payload;
    },
    // GET PRIVACY
    getPrivacySuccess(state, action) {
      state.isLoading = false;
      state.privacy = action.payload;
    },
    // GET TERMS
    getTermsSuccess(state, action) {
      state.isLoading = false;
      state.terms = action.payload;
    },
    // GET STORE TERMS and Manual
    getStoreTermsSuccess(state, action) {
      state.isLoading = false;
      state.storeterms = action.payload;
    },
    getStoreManualSuccess(state, action) {
      state.isLoading = false;
      state.storemanual = action.payload;
    },
    // GET VENDOR TERMS and Manual
    getVendorTermsSuccess(state, action) {
      state.isLoading = false;
      state.vendorterms = action.payload;
    },
    getVendorManualSuccess(state, action) {
      state.isLoading = false;
      state.vendormanual = action.payload;
    },
    // GET AGENT TERMS and Manual
    getAgentTermsSuccess(state, action) {
      state.isLoading = false;
      state.agentterms = action.payload;
    },
    getAgentManualSuccess(state, action) {
      state.isLoading = false;
      state.agentmanual = action.payload;
    },
  }
});

export default slice.reducer;


export function getFaqs() {
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {      
      const response = await api.get('/api/cms/userfaq/');
      console.log(response);
      dispatch(slice.actions.getFaqsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getContent(contenttype) {
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {      
      const response = await api.get(`/api/cms/content/cc/?q=${contenttype}`)
      dispatch(slice.actions.getContentSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getPrivacy() {
  const contenttype='pp'
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {      
      const response = await api.get(`/api/cms/content/cc/?q=${contenttype}`)
      dispatch(slice.actions.getPrivacySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getTerms() {
  const contenttype='ct'
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {      
      const response = await api.get(`/api/cms/content/cc/?q=${contenttype}`)
      dispatch(slice.actions.getTermsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// -----------------------------------------------------------

export function getStoreterms() {
  const contenttype='st'
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {      
      const response = await api.get(`/api/cms/content/cc/?q=${contenttype}`)
      dispatch(slice.actions.getStoreTermsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getStoreManual() {
  const contenttype='sm'
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {      
      const response = await api.get(`/api/cms/content/cc/?q=${contenttype}`)
      dispatch(slice.actions.getStoreManualSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// -----------------------------------------------------------

export function getVendorterms() {
  const contenttype='vt'
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {      
      const response = await api.get(`/api/cms/content/cc/?q=${contenttype}`)
      dispatch(slice.actions.getVendorTermsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getVendorManual() {
  const contenttype='vm'
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {      
      const response = await api.get(`/api/cms/content/cc/?q=${contenttype}`)
      dispatch(slice.actions.getVendorManualSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// -----------------------------------------------------------

export function getAgentterms() {
  const contenttype='at'
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {      
      const response = await api.get(`/api/cms/content/cc/?q=${contenttype}`)
      dispatch(slice.actions.getAgentTermsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAgentManual() {
  const contenttype='am'
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {      
      const response = await api.get(`/api/cms/content/cc/?q=${contenttype}`)
      dispatch(slice.actions.getAgentManualSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}