import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import checkmarkFill from '@iconify/icons-eva/checkmark-fill';
// material
import {
  Box,
  Grid,
  Step,
  Stepper,
  Container,
  StepLabel,
  StepConnector
} from '@mui/material';
import { withStyles } from '@mui/styles';
// routes
import { PATH_PAGE } from '../../routes/paths';
// hooks
import useIsMountedRef from '../../hooks/useIsMountedRef';
import useCart from '../../hooks/useCart';
// redux
import {
  getCart, increaseQuantity, decreaseQuantity,
  deleteCart, getDiscounts, applyPoints,
  removePoints,
  addAddress, addNewAddress, applyShipping, applyTimeSlot,
  onNextStep, CompleteOrder, resetCart, getTimeSlots
} from '../../redux/slices/product';
// components
import Page from '../../components/Page';
import {
  CheckoutCart,
  CheckoutPayment,
  CheckoutOrderComplete,
  CheckoutBillingAddress
} from '../../sections/shop/checkout';


// ----------------------------------------------------------------------

const STEPS = ['Cart', 'Billing & address', 'Payment'];

const QontoConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 20px)',
    right: 'calc(50% + 20px)'
  },
  active: {
    '& $line': { borderColor: theme.palette.primary.main }
  },
  completed: {
    '& $line': { borderColor: theme.palette.primary.main }
  },
  line: {
    borderTopWidth: 2,
    borderColor: theme.palette.divider
  }
}))(StepConnector);

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool
};

function QontoStepIcon({ active, completed }) {
  return (
    <Box
      sx={{
        zIndex: 9,
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: active ? 'primary.main' : 'divider',
        bgcolor: 'background.default'
      }}
    >
      {completed ? (
        <Box
          component={Icon}
          icon={checkmarkFill}
          sx={{ zIndex: 1, width: 20, height: 20, color: 'primary.main' }}
        />
      ) : (
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: '50%',
            backgroundColor: 'currentColor'
          }}
        />
      )}
    </Box>
  );
}

export default function Checkout() {
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();
  const navigate = useNavigate();
  const {
    // getCart,
    onGotoStep,
    onBackStep,
    // onNextStep,
    // increaseQuantity,

  } = useCart();
  const { checkout, discountcoopens, timeslots } = useSelector((state) => state.product);
  const {
    cart,
    total,
    billing,
    address,
    discount,
    subtotal,
    shipping,
    points,
    usepoints,
    deliveryoption,
    deliveryslot,
    activeStep
  } = checkout;
  const isComplete = activeStep === STEPS.length;

  useEffect(() => {
    dispatch(getCart());
    dispatch(getDiscounts());
    dispatch(getTimeSlots())
  }, [dispatch]);

  /*
    useEffect(() => {
      if (isMountedRef.current) {
       dispatch(getCart());
      }
    },[dispatch, isMountedRef] );
    */

  useEffect(() => {
    if (activeStep === 1) {
      // dispatch(createBilling(null));
    }
  }, [dispatch, activeStep]);

  const handleNextStep = () => {
    dispatch(onNextStep());
  };

  const handleBackStep = () => {
    dispatch(onBackStep());
  };

  const handleGotoStep = (step) => {
    dispatch(onGotoStep(step));
  };

  const handleResetStep = () => {
    dispatch(resetCart());
    navigate(PATH_PAGE.home);
  };

  const handleDeleteCart = (product) => {
    dispatch(deleteCart(product));
  };

  const handleIncreaseQuantity = (product) => {
    dispatch(increaseQuantity(product));
  };

  const handleDecreaseQuantity = (product) => {
    dispatch(decreaseQuantity(product));
  };

  const handleApplyPoints = (discount) => {
    dispatch(applyPoints(discount));
  };

  const handleRemovePoints = () => {
    dispatch(removePoints());
  };

  const handleApplyShipping = (value) => {
    dispatch(applyShipping(value));
  };
  const handleApplyTimeSlot = (value) => {
    dispatch(applyTimeSlot(value))
  }
  const handleAddAddress = (address) => {
    dispatch(addAddress(address))
  }

  const handleAddNewAddress = (address) => {
    dispatch(addNewAddress(address))
  }

  const handleComplete = (address) => {
    dispatch(CompleteOrder(address))
  }
  console.log(cart)
  const renderContent = () => {
    if (activeStep === 1) {
      return (
        <CheckoutBillingAddress
          cart={cart}
          total={total}
          subtotal={subtotal}
          shipping={shipping}
          discount={discount}
          points={points}
          usepoints={usepoints}
          onBackStep={handleBackStep}
          onNextStep={handleNextStep}
          onAddAddress={handleAddAddress}
          onAddNewAddress={handleAddNewAddress}
        />
      );
    }
    if (activeStep === 2) {
      return (
        <CheckoutPayment
          total={total}
          billing={address}
          subtotal={subtotal}
          discount={discount}
          shipping={shipping}
          points={points}
          usepoints={usepoints}
          activepoints={false}
          onApplyPoints={handleApplyPoints}
          onRemovePoints={handleRemovePoints}
          onBackStep={handleBackStep}
          onComplete={handleComplete}
          onGotoStep={handleGotoStep}
          onApplyShipping={handleApplyShipping}
          onApplyTimeSlot={handleApplyTimeSlot}
          deliveryoption={deliveryoption}
          deliveryslot={deliveryslot}
          DELIVERY_SLOTS={timeslots}
        />
      );
    }
    return (
      <CheckoutCart
        cart={cart}
        total={total}
        subtotal={subtotal}
        discount={discount}
        shipping={shipping}
        points={points}
        usepoints={usepoints}
        activepoints={false}
        activeStep={activeStep}
        onNextStep={handleNextStep}
        onDelete={handleDeleteCart}
        onIncreaseQuantity={handleIncreaseQuantity}
        onDecreaseQuantity={handleDecreaseQuantity}
        discountcoopens={discountcoopens}
      />
    );
  };

  return (
    <Page title="Checkout">
      <Container>

        <Grid container justifyContent={isComplete ? 'center' : 'flex-start'}>
          <Grid item xs={12} md={8} sx={{ mb: 5 }}>
            <Stepper
              alternativeLabel
              activeStep={activeStep}
              connector={<QontoConnector />}
            >
              {STEPS.map((label) => (
                <Step key={label}>
                  <StepLabel
                    StepIconComponent={QontoStepIcon}
                    sx={{
                      '& .MuiStepLabel-label': {
                        typography: 'subtitle2',
                        color: 'text.disabled'
                      }
                    }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
        </Grid>

        {isComplete ? (
          <CheckoutOrderComplete
            isComplete={isComplete}
            onReset={handleResetStep}
          />
        ) : (
          renderContent()
        )}
      </Container>
    </Page>
  );
}
