import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
// redux
import { login, register, logout } from '../redux/slices/authJwt';

// ----------------------------------------------------------------------

useAuth.propTypes = {
  method: PropTypes.oneOf(['jwt', 'firebase'])
};

// eslint-disable-next-line consistent-return
export default function useAuth(method = 'jwt') {

  // JWT Auth
  const dispatch = useDispatch();
  const { user, isLoading, status, isAuthenticated } = useSelector(
    (state) => state.authJwt
  );

  // JWT Auth
  if (method === 'jwt') {
    return {
      method: 'jwt',
      user,
      isLoading,
      isAuthenticated,
      status,

      login: ( username, password ) =>
        dispatch(
          login(
            username,
            password
          )
        ),

      register: ({ email, password, firstName, lastName }) =>
        dispatch(
          register({
            email,
            password,
            firstName,
            lastName
          })
        ),

      logout: () => dispatch(logout()),

      resetPassword: () => {},

      updateProfile: () => {}
    };
  }

}
