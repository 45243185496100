import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme, styled } from '@mui/styles';
import { Grid, Skeleton } from '@mui/material';
import { MotionInView } from '../../../components/animate';
import getVariant from '../../../utils/getVariant';
// material

import { PATH_PAGE } from '../../../routes/paths';

// ----------------------------------------------------------------------

const SkeletonLoad = (
  <>
    {[...Array(12)].map((item, index) => (
      <Grid item xs={12} sm={6} md={3} key={index}>
        <Skeleton variant="rectangular" width="100%" sx={{ paddingTop: '115%', borderRadius: 2 }} />
      </Grid>
    ))}
  </>
);
// ----------------------------------------------------------------------
const ClientImgStyle = styled('img')(({ theme }) => ({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'fill',
  position: 'relative',
  borderRadius: '12px',
  filter: "grayscale(90%)" ,
  '&:hover': {
   
    filter: "grayscale(0%)" ,
  }
}));


ClientCard.propTypes = {
  item: PropTypes.object,
};

function ClientCard({ item }) {
  const theme = useTheme();
  const { image, title, id, slug } = item;
  const linkTo = "#"; // `${PATH_PAGE.root}/brand/${slug}`;
  return (
    <Grid to={linkTo} component={RouterLink} sx={{padding:theme.spacing(15)}}>
      <ClientImgStyle key={id} alt={title} src={image} sx={{ cursor: 'pointer', boxShadow: theme.shadows[2] }} />
    </Grid>
  );
}

ClientList.propTypes = {
  clients: PropTypes.array.isRequired,
  isLoad: PropTypes.bool,
  other: PropTypes.object,
};

export default function ClientList({ clients, isLoad, ...other }) {
  const theme = useTheme();

  return (
    <Grid container spacing={theme.spacing(1)} {...other}>
      {clients.map((client, index) => (
        <Grid key={index} item xs={3} lg={2}>
          <MotionInView key={index} variants={getVariant('slideInUp')}>
            <ClientCard item={client} />
          </MotionInView>
        </Grid>
      ))}
      {isLoad && SkeletonLoad}
    </Grid>
  );
}
