import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../utils/apiwrapper';

const initialState = {
  isLoading: false,
  error: false,
  tagdata:{}

};

const slice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET BRAND
    getTagsSuccess(state, action) {
      state.isLoading = false;
      state.tagdata = action.payload;
    },
  }
});

export default slice.reducer;


export function getbyTags(slug,store) {
    return async dispatch => {
      dispatch(slice.actions.startLoading());
      try {
        let storeid=''
        if (store){
         storeid = `&str=${store.slug}`;
        }
        const response = await api.get(`/api/page/t/?q=${slug}${storeid}`)
        dispatch(slice.actions.getTagsSuccess(response.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }
  